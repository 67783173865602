import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  //buttonGroup,
  marginTopSubstitute,
  borderBlue,
  //borderRed,
  borderPurple,
  borderGreen,
  borderOrange,
  borderSkyBlue,
  // borderSunYellow,
  borderRosePink,
  borderSpringGreen,
  // borderSoftOrange,
  desktopNavContainer,
  desktopNav,
  desktopContainer,
  sectionContent,
  // content,
  hasSideNav,
  moduleNavButton,
  barTop,
  barMid,
  barBot,
  moduleNavOpen,
  moduleNavButtonOpen,
} from "./subpage.module.scss";
//import Slider from "../components/slider";

const PortalePage = () => {
  const [mobileModuleNavIsVisible, setMobileModuleNavIsVisible] =
    React.useState(false);

  const handleMobileModuleNavClick = () => {
    setMobileModuleNavIsVisible(!mobileModuleNavIsVisible);
  };

  return (
    <Layout pageTitle="Portale" neutralPage={true} smoothScroll={true}>
      <main className={mainContent}>
        <section className={`${introSection} ${hasSideNav}`}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>ti-e Portalwelt</h1>
                <p className="text-medium">
                  Mit unseren the i-engineers Portalen (ti–e Portalen) haben Sie
                  alles im Griff.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <div className={desktopNavContainer}>
          <div
            className={`${desktopNav} ${
              mobileModuleNavIsVisible ? moduleNavOpen : ""
            }`}
          >
            <div>
              <h3>ti–e Portale</h3>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#p-p"
              >
                ti–e Patientenportal (p-p)
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#z-p"
              >
                ti–e Zuweiserportal (z-p)
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#k-p"
              >
                ti–e Klinikportal (k-p)
              </Link>
              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#v-p"
              >
                ti–e Verlegungsportal (v-p)
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#m-p"
              >
                ti–e Meldeportal (m-p)
              </Link>

              <Link
                onClick={() => setMobileModuleNavIsVisible(false)}
                to="#nav"
              >
                ti–e Navigation (nav)
              </Link>
            </div>
          </div>
          <div className={desktopContainer}>
            <section className={borderBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <p>
                      Mobile Portallösungen von the i-engineers unterstützen
                      Patienten & Patientinnen, Zuweisende und Spitalpersonal.
                    </p>

                    <p>
                      Mit der integrierten Gesundheitsplattform haben Sie und
                      Ihre Patienten & Patientinnen relevante Daten jederzeit im
                      Griff und werden Teil von automatisierten Prozessen.{" "}
                    </p>
                    <p>
                      Die durchgängige digitale Begleitung über ti–e Portale mit
                      enger Verzahnung in Ihre Fachapplikationen und zu Partnern
                      ermöglichen eine optimale Versorgung und effiziente
                      Abläufe.
                    </p>

                    <StaticImage
                      src="../assets/TIE_IGP_Portale_Web_2.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>

                  <div className={col12}>
                    <h3>Videos zur ti–e Portalwelt</h3>
                    <p>
                      Nachfolgend zwei animierte Videos, die das Zusammenspiel
                      von ti–e Zuweiserportal, ti–e Klinikportal und ti–e
                      Patientenportal in der Patient-Journey von Kathrin Huber
                      zeigen.{" "}
                    </p>

                    <p>Aus Sicht des Patienten:</p>
                    <a
                      href="https://www.tie.ch/landingpages/portal-journey"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../assets/p-p-PatientJourney-Cover.jpg"
                        alt="Interview mit Andreas Rygol"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </a>

                    <p className={marginTopSubstitute}>
                      Aus Sicht des Spitals:
                    </p>

                    <a
                      href="https://www.tie.ch/landingpages/portal-journey"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        src="../assets/EFSpitalJourneyVideo.png"
                        alt="Interview mit Andreas Rygol"
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </a>
                  </div>
                </div>

                {/* <div
                  id="content"
                  className={`${row} ${content}`}
                  style={{ paddingTop: 24 }}
                >
                  <div className={col12}>
                    <h3>TIE Portale</h3>
                    <ul>
                      <li>
                        <Link to="#p-p">Patientenportal</Link>
                      </li>
                      <li>
                        <Link to="#z-p">Zuweiserportal</Link>
                      </li>
                      <li>
                        <Link to="#k-p">Klinikportal</Link>
                      </li>
                      <li>
                        <Link to="#b-p">Belegungportal</Link>
                      </li>
                     </ul>
                  </div>
                   <div className={col6}> </div>
                </div>*/}
              </div>
            </section>

            <section className={borderSpringGreen} id="p-p">
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Patientenportal</h1>
                    <p className="text-medium">
                      Mit uns begleiten Sie Ihre Patienten digital – Das Modul
                      ti–e Patientenportal integriert über health-engine® die
                      interne elektronische Patientenakte mit den Primärsystemen
                      der Klinik und ermöglicht so die Digitalisierung
                      patientenbezogener Prozesse und Workflows. Für Kunden in
                      Deutschland beinhaltet die Lösung auch eine Schnittstelle
                      zur Telematikinfrastruktur.
                    </p>
                    <p>
                      Das Modul ti–e Patientenportal wird wahlweise als SaaS aus
                      der Cloud oder als On-Premise Lösung angeboten.
                    </p>

                    {/* <div className={buttonGroup}><a 
                    href="https://p-p.swcode.io/demo/portal"
                    className="button-outline orange" 
                    target="_blank" 
                    rel="noreferrer"> Patienportal Demo </a> </div>*/}
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/p-p-funktionen-3.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>

                  <div className={col12}>
                    <h3>
                      Nutzen für den <span className="italic">Patienten</span>
                    </h3>
                    <ul>
                      <li>
                        Durchgängige digitale Unterstützung vor, während und
                        nach dem Klinikaufenthalt
                      </li>
                      <li>
                        Gewinn von Struktur und Ablaufsicherheit durch die
                        individuell gestaltete Aufgabenliste
                      </li>
                      <li>
                        Schnellerer Zugriff auf Dokumente und Termine für
                        effizientere Prozesse und gesteigerte Transparenz
                      </li>
                      <li>
                        Effizienter und sicherer Datenaustausch mit
                        medizinischem Personal reduziert Wartezeiten
                      </li>
                      <li>
                        Vermeidung mehrfacher, manueller Dateneingaben für
                        gesteigerte Effizienz
                      </li>
                      <li>
                        Neue Kommunikationskanäle, wie zum Beispiel Chat oder
                        Videosprechstunde im Vorfeld einer Behandlung
                      </li>
                      <li>
                        Dokumenten-Upload ermöglicht Behandlern Einsicht in die
                        persönliche Krankengeschichte
                      </li>
                      <li>
                        Steigerung der allgemeinen Zufriedenheit durch
                        optimierte digitale Unterstützung
                      </li>
                      <li>
                        Informationen zur Einrichtung, zur Behandlung, zur
                        Nachsorge geben SicherheitVerbesserte Orientierung
                        innerhalb der Einrichtung durch ein optionales
                        Navigationsfeature
                      </li>
                    </ul>
                  </div>
                  <div className={col12}>
                    <h3>
                      Nutzen für die <span className="italic">Einrichtung</span>
                    </h3>
                    <ul>
                      <li>
                        Individuelle Aufgabenzuweisung und Prozessüberwachung
                        für Patienten, inklusive Erinnerungen und Steuerung
                      </li>
                      <li>
                        Effiziente Datenerhebung und -verarbeitung unterstützen
                        die Patientenaufnahme, Behandlung und Überleitung
                      </li>
                      <li>
                        Digitale Fragebögen reduzieren manuellen Aufwand und
                        optimieren Arbeitsprozesse
                      </li>
                      <li>
                        Automatisierung von Prozessen steigert Effizienz,
                        Qualität und minimiert Leerlaufzeiten
                      </li>
                      <li>
                        Elektronischer Dokumentenversand ermöglicht signifikante
                        Kosteneinsparungen
                      </li>
                      <li>
                        Basis und Vehikel für optionale Zusatzangebote, sowie
                        Marketing Ihrer Einrichtung
                      </li>
                      <li>
                        Erhöhung der Einrichtungsattraktivität zur effektiveren
                        Patientenakquise
                      </li>
                      <li>
                        Steigerung der Zufriedenheit bei Behandlungspersonal
                        durch verbesserte Arbeitsprozesse
                      </li>
                    </ul>

                    <div className={col12}>
                      <h3 className={marginTopSubstitute}>
                        Durchgehende Unterstützung Entlassmanagement
                      </h3>
                      <StaticImage
                        src="../assets/entlassmanagement.png"
                        alt=""
                        placeholder="none"
                        layout="fullWidth"
                        formats={["auto", "webp", "avif"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="z-p" className={borderPurple}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Zuweiserportal</h1>
                    {/*<div className={buttonGroup}><a 
                    href="https://z-p.swcode.io/demo/portalzp"
                    className="button-outline orange" 
                    target="_blank" 
                    rel="noreferrer"> Zuweiserportal Demo </a> </div>*/}
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/z-p-funktionen.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                      Nutzen für <span className="italic">Zuweiser</span>
                    </h3>
                    <ul>
                      <li>
                        Digitaler Zugang für Zuweiser ermöglicht direkte
                        Einsicht in Patientenbelange
                      </li>
                      <li>
                        Verbesserte Transparenz für niedergelassene Ärzte und
                        medizinische Einrichtungen
                      </li>
                      <li>
                        Effizientes Auftragsmanagement via Portal steigert
                        Qualität und spart Zeit
                      </li>
                      <li>
                        Minimierung manueller Tätigkeiten und Förderung von
                        Prozessautomatisierungen
                      </li>
                      <li>
                        Ermöglichung von Ad-hoc-Kommunikation und Konsilen mit
                        der Einrichtung
                      </li>
                      <li>
                        Steigerung der Zufriedenheit bei einweisenden Ärzten
                      </li>
                      <li>
                        Ein Zuweiserportal ermöglicht es niedergelassenen Ärzten
                        und anderen Zuweisern, sich effizient mit Kliniken und
                        anderen medizinischen Einrichtungen auszutauschen, was
                        die Koordination der Patientenversorgung verbessert
                      </li>
                      <li>
                        Zuweiser haben über das Portal direkten Zugang zu
                        relevanten Patientendaten, was die Diagnose und
                        Behandlungsplanung beschleunigen kann
                      </li>
                      <li>
                        Das Portal erleichtert den Prozess der Überweisung von
                        Patienten, indem es automatisierte Funktionen für die
                        Einreichung und Verfolgung von Überweisungen bietet
                      </li>
                      <li>
                        Zuweiser können den Status ihrer Patienten in Echtzeit
                        verfolgen, was zu einer besseren Nachsorge und
                        kontinuierlichen Betreuung führt
                      </li>
                      <li>
                        Behandlungspläne und -ergebnisse können einfach über das
                        Portal geteilt werden, wodurch Zuweiser leichter in die
                        Behandlung ihrer Patienten einbezogen werden können
                      </li>
                    </ul>
                  </div>
                  <div className={col12}>
                    <h3>
                      Nutzen für den <span className="italic">Patienten</span>
                    </h3>
                    <ul>
                      <li>
                        Rund um die Uhr verfügbare Terminbuchung und -übersicht
                        für zugewiesene Patienten
                      </li>
                      <li>
                        Das Portal ermöglicht eine einfache Terminbuchung und
                        -koordination zwischen Zuweisern und Einrichtungen, was
                        Wartezeiten für Patienten reduziert
                      </li>
                      <li>
                        Durch den verbesserten Informationsfluss zwischen
                        Zuweisern und Behandlungseinrichtungen kann die
                        Patientensicherheit erhöht werden, indem
                        Missverständnisse und Informationslücken vermieden
                        werden.
                      </li>
                    </ul>
                  </div>

                  <div className={col12}>
                    <h3>
                      Nutzen für die <span className="italic">Einrichtung</span>
                    </h3>
                    <ul>
                      <li>
                        Zeitliche und finanzielle Einsparungen durch digitale
                        Kommunikation und Dokumentenversand
                      </li>
                      <li>
                        Steigerung der Zufriedenheit bei einweisenden Ärzten und
                        dem Personal der Einrichtung
                      </li>
                      <li>
                        Zuweiserportale, der modernen Sicherheitsstandards
                        erfüllen, gewährleisten den Schutz sensibler
                        Patientendaten und unterstützen die Einhaltung von
                        Datenschutzrichtlinien
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section id="k-p" className={borderSkyBlue}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Klinikportal</h1>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/k-p-funktionen.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                      Nutzen für die <span className="italic">Einrichtung</span>
                    </h3>
                    <ul>
                      <li>
                        Flexibles und benutzerfreundliches Management von
                        Patientendaten mit Zugriff über verschiedene Endgeräte
                      </li>
                      <li>
                        Unterstützung aller Arten von digitalen
                        Leistungsanforderungen (Order Entry), für eine
                        effiziente Auftragsverwaltung
                      </li>
                      <li>
                        Optimiertes Auftragsmanagement für interne und externe
                        Anforderungen zur Steigerung von Zeitersparnis,
                        Effizienz und Qualität
                      </li>
                      <li>
                        Mobile digitale Unterstützung für Visiten,
                        einschließlich Tablet-basierter Eingabemöglichkeiten,
                        verbessert die Flexibilität und Effizienz
                      </li>
                      <li>
                        Erweiterte Terminbuchungs- und Übersichtsfunktionen, die
                        das TIE-Patientenportal ergänzen und interne Abläufe der
                        Klinik optimieren
                      </li>
                      <li>
                        Reduzierung von Zeit- und Kostenaufwand durch effiziente
                        digitale Kommunikationswege und Dokumentenversand
                      </li>
                      <li>
                        Vereinfachte interne und externe Kommunikation,
                        einschließlich der leichten Organisation und
                        Durchführung von Konsilen
                      </li>
                      <li>
                        Einfache Initiierung der Überleitung in Nachsorge oder
                        ambulante Pflege / SPITEX (CH) aus dem Klinikportal, was
                        den Übergangsprozess vereinfacht
                      </li>
                      <li>
                        Ständige Aktualität für Kliniker durch die
                        Informationsfunktion des Portals, gewährleistet die
                        neuesten Erkenntnisse und Richtlinien
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section id="v-p" className={borderOrange}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Verlegungsportal</h1>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/v-p-funktionen.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>
                      Nutzen für die{" "}
                      <span className="italic">Einrichtung & Patienten</span>
                    </h3>
                    <ul>
                      <li>
                        Effiziente und strukturierte Patientenüberleitung mit
                        vollständigen Unterlagen, was Zeit spart und die
                        Qualität der Nachsorge verbessert
                      </li>
                      <li>
                        Individuell anpassbare Such- und Anzeigefunktionen
                        erhöhen die Relevanz und Trefferquote bei der Auswahl
                        von Nachsorgeoptionen
                      </li>
                      <li>
                        Direktes Ausfüllen und Bereitstellen notwendiger
                        Formulare über das Portal, was den Verwaltungsaufwand
                        minimiert
                      </li>
                      <li>
                        Reibungslose Integration mit etablierten Partnern z.B.
                        OPAN (CH) oder Recare (DE) durch vorhandene
                        Schnittstellen, erleichtert die Koordination der
                        Nachsorge
                      </li>
                      <li>Ver- und Nachsorge für</li>
                      <ul>
                        <li>
                          <span className="text-blue">Akuthaus:</span> Einfache
                          Überweisung von Patienten an Akuthäuser für
                          weiterführende Behandlungen
                        </li>
                        <li>
                          <span className="text-blue">Reha-Einrichtung: </span>
                          Unkomplizierte Verlegung von Patienten in
                          Rehabilitationseinrichtungen zur Förderung der
                          Erholung
                        </li>
                        <li>
                          <span className="text-blue">Ambulante Pflege: </span>
                          Direkte Überleitung von Patienten in ambulante Pflege,
                          um eine kontinuierliche Versorgung im häuslichen
                          Umfeld zu gewährleisten
                        </li>
                        <li>
                          <span className="text-blue">
                            Notfallüberweisung:{" "}
                          </span>
                          Schnelle Organisation von Notfallüberweisungen, um
                          akute medizinische Bedürfnisse effektiv zu adressieren
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section id="m-p" className={borderRosePink}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Meldeportal</h1>
                    <p className="text-medium">
                      Ob auf der Intensivstation oder auf einer Bettenstation,
                      der Überblick darüber, wer welche Patienten betreut
                      erleichtert die Arbeit im Alltag.
                    </p>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/m-p-funktionen.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="nav" className={borderGreen}>
              <div className={sectionContent}>
                <div className={row}>
                  <div className={col12}>
                    <h1>ti–e Navigation</h1>
                  </div>
                </div>

                <div className={row}>
                  <div className={col12}>
                    <h3>Übersicht Funktionen</h3>
                    <StaticImage
                      src="../assets/navigation.png"
                      alt=""
                      placeholder="none"
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <button
        className={`${moduleNavButton} ${
          mobileModuleNavIsVisible ? moduleNavButtonOpen : ""
        }`}
        onClick={handleMobileModuleNavClick}
      >
        <span className={barTop}></span>
        <span className={barMid}></span>
        <span className={barBot}></span>
      </button>
    </Layout>
  );
};

export default PortalePage;
